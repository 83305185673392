// Admin
import Home from "../pages/admin/Home";

//Customer

// Auth
import SignIn from "../pages/auth/signIn/SignIn";
import ResetPassword from "../pages/auth/ResetPassword";
import RecoverPassword from "../pages/auth/RecoverPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

import {
  faArchive,
  faCalendar,
  faClock,
  faCogs,
  faHome,
  faMoneyBill,
  faUserFriends,
  faUsers,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";

import Employees from "../pages/admin/Employees";
import Crews from "../pages/admin/Crews";
import SubContractors from "../pages/admin/SubContractors";
import NonJobTimes from "../pages/admin/NonJobTimes";
import WorkOrders from "../pages/admin/WorkOrders";
import WorkOrderDetails from "../pages/admin/WorkOrderDetails";
import CrewManagement from "../pages/admin/CrewManagement";
import JobSchedule from "../pages/admin/schedules/JobSchedule";
import Users from "../pages/admin/Users";
import SupervisorSchedule from "../pages/admin/schedules/SupervisorSchedule";
import CrewSchedule from "../pages/admin/schedules/CrewSchedule";
import GeneralSettings from "../pages/admin/settings/GeneralSettings";
import PayrollReport from "../pages/admin/reports/payroll/PayrollReport";
import LaborUploadReport from "../pages/admin/reports/LaborUploadReport";
import GPUploadReport from "../pages/admin/reports/GPUploadReport";
import WeeklyPayroll from "../pages/admin/reports/payroll/WeeklyPayroll";
import SuperFieldNotesReport from "../pages/admin/reports/SuperFieldNotesReport";
import ExpenseReport from "../pages/admin/reports/ExpenseReport";
import TravelAndLiving from "../pages/admin/reports/TravelAndLiving";
import CashAdvance from "../pages/admin/reports/CashAdvance";
import ChangeOrdersReport from "../pages/admin/reports/ChangeOrdersReport";
import CompletedJobsReport from "../pages/admin/reports/CompletedJobsReport";
import ChangeOrdersByWeek from "../pages/admin/reports/ChangeOrdersByWeek";
import UsageReport from "../pages/admin/reports/UsageReport";
import PayrollLockReport from "../pages/admin/reports/PayrollLockReport";
import EmployeeRoles from "../pages/admin/EmployeeRoles";
import ChangeOrderNotifications from "../pages/admin/settings/ChangeOrderNotifications";
import { utilsHelper } from "../helpers/utilsHelper";
import AppExplanationTexts from "../pages/admin/settings/AppExplanationTexts";
import Expenses from "../pages/admin/Expenses";
import TravelAndLivingSummary from "../pages/admin/reports/TravelAndLivingSummary";
import InspectionsReport from "../pages/admin/reports/InspectionsReport";
import PayComUpload from "../pages/admin/reports/PayComUpload";
import PayComCheck from "../pages/admin/reports/PayComCheck";
import NonJobProjects from "../pages/admin/NonJobProjects";
import SafetyNotifications from "../pages/admin/settings/SafetyNotifications";
import SafetyReport from "../pages/admin/reports/SafetyReport";
import NonJobProjectsReport from "../pages/admin/reports/NonJobProjectsReport";
import JSAReport from "../pages/admin/reports/JSAReport";
import InspectionNotifications from "../pages/admin/settings/InspectionNotifications";
import PartsReport from "../pages/admin/reports/PartsReport";

const USER_ROLE_EXECUTIVE = 1;
const USER_ROLE_MANAGER = 2;

// Routes
export const adminRoutes = (workOrderStatuses = []) => [
  {
    path: "/home",
    name: "Home",
    sidebar: false,
    component: Home,
  },
  {
    path: "/work-orders",
    name: "Work Orders",
    icon: faHome,
    sidebar: true,
    entries: [
      {
        path: `/work-orders/all/`,
        name: "All",
        sidebar: true,
        component: WorkOrders,
      },
      {
        badgeColor: "secondary",
        name: "Recently Added",
        sidebar: true,
        path: `/work-orders/all/recently-added`,
        component: WorkOrders,
      },
      ...workOrderStatuses
        .filter((status) => status.status !== "Change Order Processing")
        .map((status) => ({
          name: `${status.status}`,
          path: `/work-orders/all/${status.id || ""}`,
          sidebar: true,
          component: WorkOrders,
        })),
      {
        path: `/work-orders/all/:statusId`,
        sidebar: false,
        component: WorkOrders,
      },
    ],
  },
  {
    path: "/calendar",
    name: "Calendar",
    icon: faCalendar,
    sidebar: true,
    entries: [
      {
        icon: null,
        name: "Job Schedule",
        path: `/calendar/job-schedule`,
        sidebar: true,
        component: JobSchedule,
      },
      {
        icon: null,
        name: "Supervisor Schedule",
        path: `/calendar/supervisor-schedule`,
        sidebar: true,
        component: SupervisorSchedule,
      },
      {
        icon: null,
        name: "Crew Schedule",
        path: `/calendar/crew-schedule`,
        sidebar: true,
        component: CrewSchedule,
      },
    ],
  },
  {
    path: `/work-orders/details/:workOrderId`,
    name: "Work Order Details",
    component: WorkOrderDetails,
  },
  {
    path: "/work-orders/details/:workOrderId/crew/:shift/:outage/members",
    name: "Crew Managment",
    component: CrewManagement,
  },
  {
    path: "/work-orders/details/:workOrderId/crew/:shift/:outage/members/:crewId",
    name: "Crew Managment",
    component: CrewManagement,
  },
  {
    path: "/work-orders/details/:workOrderId/crew/members/:crewId",
    name: "Crew Managment",
    component: CrewManagement,
  },
  {
    path: "/work-orders/details/:workOrderId/crew/members",
    name: "Crew Managment",
    component: CrewManagement,
  },
  {
    path: "/team-management",
    name: "Team Management",
    icon: faUserFriends,
    sidebar: true,
    entries: [
      {
        icon: null,
        name: "Employees",
        path: `/team-management/employees`,
        sidebar: true,
        component: Employees,
      },
      {
        icon: null,
        name: "Crews",
        path: `/team-management/crews`,
        sidebar: true,
        component: Crews,
      },
      {
        icon: null,
        name: "Contract Employees",
        path: `/team-management/contract-employees`,
        sidebar: true,
        component: SubContractors,
      },
    ],
  },
  {
    name: "Reports",
    path: "/report",
    icon: faArchive,
    sidebar: true,
    entries: [
      {
        icon: null,
        name: "Payroll Reports",
        sidebar: true,
        entries: [
          {
            name: "PayCom Upload",
            path: `/report/paycom-upload`,
            sidebar: true,
            component: PayComUpload,
          },
          {
            name: "PayCom Check",
            path: `/report/paycom-check`,
            sidebar: true,
            component: PayComCheck,
          },
          {
            icon: null,
            name: "Weekly Payroll",
            path: `/report/weekly-payroll`,
            sidebar: true,
            component: WeeklyPayroll,
          },
          {
            icon: null,
            name: "Payroll Report",
            path: `/report/payroll`,
            sidebar: true,
            component: PayrollReport,
          },
          {
            icon: null,
            name: "Travel and Living",
            path: `/report/travel-and-living`,
            sidebar: true,
            component: TravelAndLiving,
          },
          {
            icon: null,
            name: "T&L Summary",
            path: `/report/travel-living-summary`,
            sidebar: true,
            component: TravelAndLivingSummary,
          },
          {
            icon: null,
            name: "Cash Advance",
            path: `/report/cash-advance`,
            sidebar: true,
            component: CashAdvance,
          },
          {
            icon: null,
            name: "Payroll Lock/Updates",
            path: `/report/payroll-lock`,
            sidebar: true,
            component: PayrollLockReport,
          },
        ],
      },
      {
        icon: null,
        name: "Accounting Reports",
        sidebar: true,
        entries: [
          {
            icon: null,
            name: "Non Job Time",
            path: `/report/non-job-time`,
            sidebar: true,
            component: NonJobProjectsReport,
          },
          {
            icon: null,
            name: "GP Labor",
            path: `/report/gp-labor`,
            sidebar: true,
            component: LaborUploadReport,
          },
          {
            icon: null,
            name: "GP Upload",
            path: `/report/gp-upload`,
            sidebar: true,
            component: GPUploadReport,
          },
          {
            icon: null,
            name: "Expenses",
            path: `/report/weekly-expense-report`,
            sidebar: true,
            component: ExpenseReport,
          },
        ],
      },
      {
        icon: null,
        name: "Misc",
        sidebar: true,
        entries: [
          {
            icon: null,
            name: "Super/Field Notes",
            path: `/report/weekly-super-field-notes-report`,
            sidebar: true,
            component: SuperFieldNotesReport,
          },
          {
            icon: null,
            name: "Safety Reports",
            path: `/report/safety-reports`,
            sidebar: true,
            component: SafetyReport,
          },
          {
            icon: null,
            name: "Change Orders",
            path: `/report/change-orders`,
            sidebar: true,
            component: ChangeOrdersReport,
          },
          {
            icon: null,
            name: "Change Orders by Week",
            path: `/report/change-orders-by-week`,
            sidebar: true,
            component: ChangeOrdersByWeek,
          },
          {
            icon: null,
            name: "Completed Jobs",
            path: `/report/completed-jobs`,
            sidebar: true,
            component: CompletedJobsReport,
          },
          {
            icon: null,
            name: "Inspections Report",
            path: `/report/inspections`,
            sidebar: true,
            component: InspectionsReport,
          },
          {
            icon: null,
            name: "Parts Report",
            path: `/report/part-reports`,
            sidebar: true,
            component: PartsReport,
          },
          {
            icon: null,
            name: "CrewConX Usage",
            path: `/report/usage-report`,
            sidebar: true,
            component: UsageReport,
          },
          {
            icon: null,
            name: "JSA Report",
            path: `/report/jsa-report`,
            sidebar: true,
            component: JSAReport,
          },
        ],
      },
    ],
  },
  {
    path: "/non-job-time",
    name: "Non Job Time",
    icon: faClock,
    sidebar: true,
    entries: [
      {
        path: `/non-job-time/all/`,
        name: "Projects",
        sidebar: true,
        component: NonJobProjects,
      },
      {
        path: `/non-job-time/history/`,
        name: "Projects History",
        sidebar: true,
        component: NonJobProjects,
      },
      {
        path: `/non-job-time/review-time/`,
        name: "Review Time",
        sidebar: true,
        component: NonJobTimes,
      },
    ],
  },
  {
    path: "/expenses",
    name: "Expenses",
    icon: faMoneyBill,
    sidebar: true,
    component: Expenses,
  },
  {
    path: "/users",
    name: "Users",
    icon: faUsers,
    sidebar: true,
    roles: [USER_ROLE_EXECUTIVE, USER_ROLE_MANAGER],
    component: Users,
  },
  {
    path: "/employee-roles",
    name: "Employee Roles",
    icon: faUserTag,
    sidebar: true,
    condition: !utilsHelper.isBetaProd(),
    roles: [USER_ROLE_EXECUTIVE, USER_ROLE_MANAGER],
    component: EmployeeRoles,
  },
  {
    name: "Settings",
    icon: faCogs,
    sidebar: true,
    roles: [USER_ROLE_EXECUTIVE],
    entries: [
      {
        icon: null,
        name: "General Settings",
        path: `/settings/general`,
        sidebar: true,
        component: GeneralSettings,
        roles: [USER_ROLE_EXECUTIVE],
      },
      {
        icon: null,
        name: "Change Order Notifications",
        path: `/settings/change-order-notifications`,
        sidebar: true,
        component: ChangeOrderNotifications,
      },
      {
        icon: null,
        name: "Inspection Notifications",
        path: `/settings/inspection-notifications`,
        sidebar: true,
        component: InspectionNotifications,
      },
      {
        icon: null,
        name: "Safety Notifications",
        path: `/settings/safety-notifications`,
        sidebar: true,
        component: SafetyNotifications,
      },
      {
        icon: null,
        name: "App Explanation Text",
        path: `/settings/app-explanation`,
        sidebar: true,
        component: AppExplanationTexts,
      },
    ],
  },
];

export const authRoutes = [
  {
    path: "/auth/sign-in",
    name: "Ingresar",
    component: SignIn,
  },
  {
    path: "/auth/reset",
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/auth/forgot/:code",
    name: "Recover Password",
    component: RecoverPassword,
  },
  {
    path: "/auth/404",
    name: "404 Page",
    component: Page404,
  },
  {
    path: "/auth/500",
    name: "500 Page",
    component: Page500,
  },
];
