import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Loader from "../../../Loader";
import TooltipItem from "../../../TooltipItem";

const EditAnswerSalesReportItemsModal = ({ onSubmit, onClose, question }) => {
  const inspectionAnswer = question.inspectionAnswers[0];
  const inspectionSaleItem = inspectionAnswer.inspectionSaleItem;
  const [inputItems, setInputItems] = useState(
    inspectionSaleItem && inspectionSaleItem.hasItems
      ? inspectionSaleItem.items.map((item) => ({ value: item }))
      : [{ value: "" }]
  );

  const [loading, setLoading] = useState(false);

  const handleInputChange = (index, value) => {
    const newInputItems = [...inputItems];
    newInputItems[index] = { ...newInputItems[index], value };
    setInputItems(newInputItems);
  };

  const removeInputField = (index) => {
    setInputItems(inputItems.filter((_, i) => i !== index));
  };

  const addInputField = () => {
    setInputItems([...inputItems, { value: "" }]);
  };

  const someItemHasValue = inputItems.some((item) => !!item.value);

  const doSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    onSubmit({
      id: inspectionSaleItem && inspectionSaleItem.id,
      inspectionAnswerId: inspectionAnswer.id,
      items: inputItems
        .filter((item) => !!item.value)
        ?.map((item) => item.value),
    });
  };

  const closeBtn = (
    <Button className="close" color="none" onClick={onClose}>
      &times;
    </Button>
  );

  const answerContent = useMemo(() => {
    let baseAnswer = inspectionAnswer.content;

    inputItems.forEach((item) => {
      if (item.value === "") return;
      if (item.value.length < 3) return;

      const regex = new RegExp(item.value, "gi");
      baseAnswer = baseAnswer.replace(
        regex,
        (match) => `<span class="font-weight-bold text-warning">${match}</span>`
      );
    });

    return baseAnswer;
  }, [inputItems, inspectionAnswer.content]);

  return (
    <Modal isOpen={true} onClosed={onClose}>
      <form onSubmit={doSubmit}>
        <ModalHeader close={closeBtn}>Edit Sales Report Items</ModalHeader>
        <ModalBody>
          {loading ? (
            <Loader size={"sm"} />
          ) : (
            <div>
              <div className="d-flex">
                <p className="pr-2 font-weight-bold">{question.content}:</p>
                <p
                  className=""
                  dangerouslySetInnerHTML={{ __html: answerContent }}
                />
              </div>
              <div className="mt-2">
                {inputItems.map((item, index) => (
                  <div key={index} className="d-flex mb-2 align-items-center">
                    <input
                      type="text"
                      className="form-control"
                      value={item.value}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                      placeholder="Enter item"
                    />
                    {inputItems.length > 1 && (
                      <TooltipItem
                        className="ml-2 cursor-pointer"
                        id={`${inspectionAnswer.id}-remove-item-tooltip`}
                        title="Remove item from the list"
                      >
                        <FontAwesomeIcon
                          onClick={() => removeInputField(index)}
                          icon={faTrashAlt}
                          className={`text-danger`}
                          style={{ paddingBottom: "1px" }}
                        />
                      </TooltipItem>
                    )}
                  </div>
                ))}
                <Button
                  color="secondary"
                  size="sm"
                  onClick={addInputField}
                  className="mt-2"
                >
                  Add Item
                </Button>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button color={"secondary"} onClick={onClose}>
            Cancel
          </Button>{" "}
          <Button
            disabled={loading || (!someItemHasValue && !inspectionSaleItem?.id)}
            color="primary"
            type="submit"
          >
            Confirm
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default EditAnswerSalesReportItemsModal;
